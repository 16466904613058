import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { functions } from "../firebase/firebase";
import { isLoaded } from "react-redux-firebase";

// MUI
import {
  AppBar,
  Button,
  Collapse,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Slide,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Drawer,
  ListItemText,
  ListSubheader
} from "@material-ui/core";

// MUI Hooks
import { makeStyles, withStyles } from "@material-ui/core/styles";

// MUI Icons
import {
  KeyboardArrowLeft,
  Menu,
  Search,
  Clear,
  Person,
  ExpandLess,
  ExpandMore,
  Info,
  Widgets,
  SettingsApplications,
  Add,
  KeyboardArrowDown
} from "@material-ui/icons";

// Util
import { goBack, push } from "connected-react-router";
import { orderBy } from "lodash";
import scrollToComponent from "react-scroll-to-component";

// Actions
import { UPDATE_SELECTION_ASPECT } from "../actions";

// Components
import TopImage from "../components/Image/TopImage";
import { lightBlue } from "@material-ui/core/colors";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  section: {
    padding: "2rem 0"
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  inactive: {
    border: "1px solid"
  },
  navigation: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main
  },
  expanded: {
    background: theme.palette.secondary.main,
    color: "white"
  },
  appBar: {
    top: "auto",
    bottom: 0,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  grow: {
    flexGrow: 1
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    "&:hover": {
      opacity: 1,
      background: theme.palette.grey[200]
    }
  },
  clientButton: {
    height: 100,
    width: 100,
    margin: "1rem",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const NewDashboard = ({
  auth,
  profile,
  reroute,
  goBack,
  location,
  data,
  selectClients
}) => {
  const classes = useStyles();

  // Status
  const [fetching, setFetching] = React.useState(false);
  const [fetchingBuildings, setFetchingBuildings] = React.useState(false);

  // Client data from API
  const [buildingData, setBuildingData] = React.useState(null);
  const [clientData, setClientData] = React.useState(data.clients);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  // Selection
  const defaultSelection = {
    client: "",
    site: "",
    building: ""
  };
  const [selection, setSelection] = React.useState(defaultSelection);
  const [buildings, setBuildings] = React.useState("");

  // Menu Clicks
  const [expand, setExpand] = React.useState({
    user: true,
    settings: false,
    widgets: false
  });

  //   Functions
  const fetchClients = functions.httpsCallable("fetchEndpoint");
  const fetchBuildings = functions.httpsCallable("fetchEndpoint");

  // Scroll to top
  let topRef = React.useRef();

  // Handlers
  const handleMenuClick = location => {
    setOpenDrawer(false);
    reroute(location);
  };

  const handleScroll = () => {
    scrollToComponent(topRef, {
      offset: 10,
      align: "top",
      duration: 500,
      ease: "inOutQuint"
    });
  };

  // Autoselector
  const autoSelect = (type, payload) => {
    // Scroll to top
    handleScroll();

    if (type === "site") {
      // Site
      if (payload.sites.length === 1) {
        let site = payload.sites[0];
        if (site.buildings.length === 1) {
          let building = site.buildings[0];
          setFetchingBuildings(true);
          const data = fetchBuildings({
            route: "buildings",
            api: profile.api
          });

          data
            .then(res => {
              setFetchingBuildings(false);
              const data = JSON.parse(res.data);
              const filteredData = data.filter(
                building => building.siteId === site.id
              );
              setBuildingData(filteredData);
            })
            .catch(e => {
              console.log(e);
            })
            .then(res => {
              setSelection({
                client: {
                  id: payload.id,
                  name: payload.name
                },
                site: {
                  id: site.id,
                  name: site.name
                },
                building: {
                  id: building.id,
                  name: building.name
                }
              });
            });
        }
      }
    }
  };

  React.useEffect(() => {
    //   Once profile is loaded, fetch if there aren't clients
    if (
      isLoaded(profile) &&
      !data.clients.length &&
      !fetching &&
      !selection.client.name
    ) {
      setFetching(true);
      const data = fetchClients({ route: "clients", api: profile.api });
      data
        .then(res => {
          if (res) {
            setFetching(false);
            setClientData(JSON.parse(res.data));
          }
        })
        .catch(e => {
          setFetching(false);
          console.log(e.message);
          return e;
        });
    }
    // eslint-disable-next-line
  }, [data.clients, profile]);

  // Title Greeting
  const greeting = ({ profile }) => {
    if (!profile) return "";

    let hours = new Date().getHours();
    if (hours > 4 && hours < 12) {
      return `Good Morning, ${profile.firstName}`;
    } else if (hours >= 12 && hours < 5) {
      return `Good Afternoon, ${profile.firstName}`;
    } else if (hours >= 5 && hours < 8) {
      return `Good Evening, ${profile.firstName}`;
    } else {
      return `Welcome, ${profile.firstName}`;
    }
  };

  console.log(buildingData);

  //   Render
  return (
    <div style={{ marginBottom: "10rem" }}>
      {/* If no client selected, prompt select */}
      <Grid container>
        {/* Top image */}
        <TopImage image={require("../assets/images/DashboardImage.webp")}>
          <Grid
            container
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h4" style={{ padding: "1rem 0" }}>
                {greeting(profile)}
              </Typography>
              <Typography variant="body1">
                This is your dashboard. You can switch between sites, buildings,
                floors, and devices in the menu at the bottom.
              </Typography>
            </Grid>
            <Grid item style={{ marginTop: "2rem" }}>
              <IconButton
                onClick={() => handleScroll()}
                style={{ color: "white", border: `1px solid white` }}
              >
                <KeyboardArrowDown />
              </IconButton>
            </Grid>
          </Grid>
        </TopImage>

        {/* 
          
SECTIONS WRAPPER 
          
*/}

        <Grid
          ref={section => {
            topRef = section;
          }}
          container
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "1rem"
          }}
        >
          {/* 
            
SECTION 1: CUSTOM SHORTCUTS

*/}
          <section className={classes.section}>
            <Typography variant="h6" align="center" style={{ width: "100%" }}>
              Custom Shortcuts
            </Typography>
            <Grid
              item
              xs={12}
              style={{
                padding: "1rem",
                width: "100%",
                display: "flex",
                justifyContent: "space-around"
              }}
            >
              {[1, 2, 3, 4].map(button => {
                return (
                  <IconButton
                    key={button}
                    color="primary"
                    style={{
                      border: `1px solid ${lightBlue[300]}`,
                      borderStyle: "dashed"
                    }}
                  >
                    <Add />
                  </IconButton>
                );
              })}
            </Grid>

            {/* Explanation */}
            <Typography
              variant="body1"
              align="center"
              style={{ width: "100%" }}
            >
              {`Basic idea is to put them into the profile: Profile > CustomButtons`}
            </Typography>
            <Typography
              variant="body2"
              align="center"
              style={{ width: "100%" }}
            >
              <code>
                {"[{name: '', theme: '', icon: {}, data: {}, route: {}]"}
              </code>
            </Typography>
          </section>

          {fetching || fetchingBuildings ? (
            <LinearProgress style={{ width: "100%" }} />
          ) : (
            <Divider />
          )}

          {/* 

SECTION 2: CLIENTS

*/}
          <Collapse
            in={Boolean(
              clientData.length &&
                !selection.client.name &&
                (!fetching || !fetchingBuildings)
            )}
          >
            <section
              className={classes.section}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly"
              }}
            >
              <>
                <Typography
                  variant="h6"
                  align="center"
                  style={{ width: "100%" }}
                >
                  Client Access
                </Typography>
                {orderBy(clientData, client => client.client.name).map(
                  (client, idx) => {
                    return (
                      <Button
                        key={`${client.client.id}__${idx}`}
                        onClick={() => autoSelect("site", client.client)}
                        className={classes.clientButton}
                        disabled={fetchingBuildings}
                      >
                        {client.client.name}
                      </Button>
                    );
                  }
                )}
              </>
            </section>
          </Collapse>

          {/* 

SECTION 3: DEVICES

*/}
          <Collapse
            in={Boolean(selection.client.name)}
            style={{ width: "100%" }}
          >
            <Grid item xs={12} style={{ position: "relative" }}>
              <IconButton
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: 0,
                  margin: "1rem"
                }}
                onClick={() => setSelection({ ...selection, client: "" })}
              >
                <Clear />
              </IconButton>
              <Typography
                variant="h6"
                align="center"
                style={{ width: "100%", paddingTop: "2rem" }}
              >
                {selection.client.name}
              </Typography>
            </Grid>

            {/* Devices */}
            <Grid item xs={12} style={{ padding: "1rem" }}>
              <Typography variant="caption" style={{ wordBreak: "break-word" }}>
                {buildingData && JSON.stringify(buildingData, null, "\t")}
              </Typography>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>

      {/* 

Bottom Appbar 
        
*/}
      <Slide
        in={!fetching && Boolean(clientData.length)}
        direction="up"
        timeout={750}
      >
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="end" color="inherit">
              <KeyboardArrowLeft />
            </IconButton>

            <Fab
              color="secondary"
              aria-label="add"
              className={classes.fabButton}
            >
              <Search />
            </Fab>
            <div className={classes.grow} />

            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpenDrawer(true)}
            >
              <Menu />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Slide>

      {/* 
      
MENU

*/}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <div
          className={classes.list}
          role="presentation"
          onKeyDown={() => setOpenDrawer(false)}
        >
          <List
            component="nav"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Account Options
              </ListSubheader>
            }
          >
            {/* Personal Options */}
            <ListItem
              button
              onClick={() => setExpand({ ...expand, user: !expand.user })}
            >
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText primary="User Account Information" />
              {expand.user ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expand.user} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* Profile */}
                <ListItem
                  button
                  onClick={() => handleMenuClick("profile")}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText primary={"Profile"} />
                </ListItem>

                {/* Settings */}
                <ListItem
                  button
                  onClick={() => handleMenuClick("settings")}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <SettingsApplications />
                  </ListItemIcon>
                  <ListItemText primary={"Settings"} />
                </ListItem>

                {/* Widgets */}
                <ListItem
                  button
                  onClick={() => handleMenuClick("customButton")}
                  className={classes.nested}
                >
                  <ListItemIcon>
                    <Widgets />
                  </ListItemIcon>
                  <ListItemText primary={"Customize Dashboard"} />
                </ListItem>
              </List>
            </Collapse>

            <Divider />
            {/*  */}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

NewDashboard.propTypes = {
  data: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

export default connect(
  state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    location: state.router.location
  }),
  dispatch => ({
    goBack: () => dispatch(goBack()),
    reroute: route => dispatch(push(route)),
    selectClients: clients =>
      dispatch({
        type: UPDATE_SELECTION_ASPECT,
        aspect: "clients",
        payload: clients
      })
  })
)(NewDashboard);
