import React from "react";
import { connect } from "react-redux";

//Routes
import { Route, Switch } from "react-router";
import { SnackbarProvider } from "notistack";

//Code Splitting
// import { asyncComponent } from "../hocs/asyncComponent";

//Components
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";

//SUITES
//Auth
export const DASHBOARD = "/";
export const LOGIN = "/login";

//Routes Object
const Routes = ({ profile, data, role }) => (
  <div style={{ background: "white" }}>
    <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
      <Switch>
        <Route
          exact
          path={DASHBOARD}
          render={() => <Dashboard profile={profile} data={data} />}
        />
        <Route exact path={LOGIN} component={Login} />
      </Switch>
    </SnackbarProvider>
  </div>
);

export default connect(state => ({
  profile: state.firebase.profile,
  data: state.data,
  role: state.firebase.profile.role
}))(Routes);
