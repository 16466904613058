import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import appReducer from "./app";
import constantsReducer from "./constants";
import dataReducer from "./data";
import selectionReducer from "./selection";
import sessionReducer from "./session";
import userReducer from "./user";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const createRootReducer = history => {
  const reducerBase = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    router: connectRouter(history),
    app: appReducer,
    constants: constantsReducer,
    data: dataReducer,
    selection: selectionReducer,
    session: sessionReducer,
    user: userReducer
  });

  const rootReducer = (state, action) => {
    if (action.type === "RESET_APP") {
      state = undefined;
    }

    return reducerBase(state, action);
  };

  return rootReducer;
};

export default createRootReducer;
