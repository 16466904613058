import React from "react";

import { compose } from "redux";
import { connect } from "react-redux";

//MUI

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Slide,
  TextField
} from "@material-ui/core";

// MUI Hocs
import { makeStyles } from "@material-ui/core/styles";

//MUI Icons
import { Visibility, VisibilityOff } from "@material-ui/icons";

//Actions
import { AUTH_SAGA, SIGN_IN_EMAIL, UPDATE_SESSION_ASPECT } from "../actions";

// UTIL
import clsx from "clsx";
import { push } from "connected-react-router";

// Logos
import TapaLogo from "../assets/icons/tapaIcon.png";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "transparent"
  },
  cardHeaderRoot: {
    padding: 0
  },
  slide: {
    height: 150,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    userSelect: "none"
  },
  error: {
    color: "red"
  },
  focusHighlight: {
    backgroundColor: "transparent"
  },
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.secondary.main,
      backgroundColor: "white"
    }
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.secondary.main,
      backgroundColor: "white"
    }
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.secondary.main
    }
  },
  notchedOutline: {},
  card: {
    justifyContent: "center",
    height: 550,
    maxWidth: 345,
    padding: "2em",
    margin: "0 auto",
    position: "relative",
    borderRadius: 0,
    boxShadow: "none"
  },
  cardHeader: {
    textAlign: "center",
    paddingBottom: "2rem"
  },
  cardContent: {
    position: "relative",
    padding: 0
  },
  slideContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1em"
  },
  textField: {
    fontSize: "1em"
  },
  noLeftBorder: {
    borderLeft: "unset"
  },
  margin: {
    margin: "1em 0"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em"
  },
  button: {
    color: "white",
    borderRadius: 0,
    padding: "1em"
  },
  bottomButton: {
    fontSize: "1em",
    letterSpacing: ".2em",
    borderRadius: 0,
    color: "white"
  },
  flipButton: {
    justifyContent: "space-between",
    padding: "1em 2em",
    borderRadius: 0,
    position: "absolute",
    bottom: 0,
    left: 0
  },
  icon: {
    transition: "transform .1s ease-in-out"
  },
  rotated: {
    transform: "rotate(180deg)"
  },
  rotatedY: {
    transform: "rotateY(180deg)"
  }
}));

const Login = ({ dispatch, reduxError, signInEmail }) => {
  // Styles
  const classes = useStyles();
  // Hooks
  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    api: "",
    password: "",
    loginEmail: "",
    loginPassword: ""
  };
  const [values, setValues] = React.useState(defaultValues);
  const { loginEmail, loginPassword } = values;

  // Other form options
  const [error, setError] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  // Effects
  // Cleanup
  React.useEffect(() => {
    setError(reduxError);

    return () => setValues(defaultValues);
    // eslint-disable-next-line
  }, [reduxError]);

  // Handlers
  const handleChange = name => event => {
    setError("");
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSignIn = event => {
    event.preventDefault();
    signInEmail({ email: loginEmail, password: loginPassword }, dispatch);
  };

  return (
    <Container
      maxWidth="md"
      style={{
        justifyContent: "flex-end",
        position: "relative",
        marginTop: "1rem"
      }}>
      <Card className={classes.card}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "2rem"
          }}>
          <img alt={"tapa logo"} src={TapaLogo} height={80} />
        </div>
        {/* Sign in error */}
        {error ? (
          <CardHeader
            className={clsx(classes.cardHeader)}
            title={error}
            subheader={error}
            subheaderTypographyProps={{
              color: "error"
            }}
          />
        ) : (
          <CardHeader
            className={clsx(classes.cardHeader)}
            title="SIGN IN"
            classes={{ root: classes.cardHeaderRoot }}
            subheader={"Enter your email and password to login"}
            subheaderTypographyProps={{
              align: "center",
              padding: "0"
            }}
          />
        )}

        {/* Default */}
        <CardContent className={clsx(classes.cardContent)}>
          <Slide
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
            className={clsx(classes.slide)}>
            <Grid
              container
              style={{ display: "flex", justifyContent: "center" }}>
              <form action="/" onSubmit={handleSignIn}>
                <Grid item xs={12}>
                  <TextField
                    className={clsx(classes.textField, classes.margin)}
                    required
                    autoFocus
                    variant="outlined"
                    fullWidth
                    value={loginEmail}
                    label={"Email"}
                    // eslint-disable-next-line
                    InputProps={{
                      value: loginEmail,
                      onChange: handleChange("loginEmail"),
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        animated: classes.labelProps,
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={clsx(classes.textField, classes.margin)}
                    required
                    variant="outlined"
                    label={"Password"}
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={loginPassword}
                    onChange={handleChange("loginPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <VisibilityOff
                              onClick={() => setShowPassword(true)}
                            />
                          ) : (
                            <Visibility
                              onClick={() => setShowPassword(false)}
                            />
                          )}
                        </InputAdornment>
                      ),
                      value: loginPassword,
                      onChange: handleChange("loginPassword"),
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        animated: classes.labelProps,
                        root: classes.cssLabel,
                        focused: classes.cssFocused
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "1em" }}>
                  <Button
                    fullWidth
                    type="submit"
                    square="true"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={e => handleSignIn(e)}
                    disabled={loginEmail === "" || loginPassword === ""}>
                    sign in
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Slide>
        </CardContent>
      </Card>
    </Container>
  );
};

const mapStateToProps = state => ({
  authUser: state.session.authUser,
  didFetchUser: state.user.didFetchUser,
  reduxError: state.session.error
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  reroute: route => dispatch(push(route)),
  clearErrors: () => {
    dispatch({
      type: UPDATE_SESSION_ASPECT,
      aspect: "error",
      payload: ""
    });
  },
  signInEmail: payload =>
    dispatch({
      type: AUTH_SAGA,
      filter: SIGN_IN_EMAIL,
      payload
    })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);
