import { createMuiTheme } from "@material-ui/core/styles";

/* 

  TYPOGRAPHY:

  HEADERS:
    Headers alternate between bold and light, decreasing in size. 
    Odd is bold, even is light

    h5, h6: No automatic padding applied due to size

  STATUS:
    Info:     subtitle1
    Warning:  subtitle2
    Error:    caption 

*/

const defaultTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#145380"
    },
    secondary: {
      main: "#14a79d"
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Roboto Slab"
      }
    },
    MuiDivider: {
      root: {
        width: "100%",
        margin: ".5rem 0 1rem 0"
      }
    }
  },
  typography: {
    fontFamily: ["Roboto Slab", "Roboto", "Helvetica", "sans-serif"].join(",")
  }
});

export default defaultTheme;
