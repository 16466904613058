import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

const defaultConfig = {
  apiKey: "AIzaSyC2pBRD-vAa0QFo8RQpxJVlHNBzy9OwTPo",
  authDomain: "tenant-portal-demo.firebaseapp.com",
  databaseURL: "https://tenant-portal-demo.firebaseio.com",
  projectId: "tenant-portal-demo",
  storageBucket: "tenant-portal-demo.appspot.com",
  messagingSenderId: "430039915497",
  appId: "1:430039915497:web:ad21c5d91efe984b7e0978"
};

if (!firebase.apps.length) {
  firebase.initializeApp(defaultConfig);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
