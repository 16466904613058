import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { useMediaQuery } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const styles = {
  "@keyframes fadeAnimation": {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  fade: {
    animation: "$fadeAnimation .5s ease"
  },
  main: {
    height: "50vh",
    width: "100%",
    maxHeight: "1000px",
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center"
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    height: "380px"
  }
};

const useStyles = makeStyles(styles);

export default function TopImage(props) {
  const { className, children, image, small, style } = props;
  const matches = useMediaQuery("(min-width:600px)");

  const classes = useStyles();
  const topImageClasses = classNames(classes.fade, {
    [classes.main]: true,
    [classes.filter]: true,
    [classes.small]: small,
    [className]: className !== undefined
  });

  return (
    <div
      className={topImageClasses}
      style={{
        ...style,
        backgroundImage: `url(${image})`
      }}
    >
      <div
        style={{
          display: "flex",
          textAlign: matches ? "start" : "center",
          padding: matches ? "4rem" : "1rem",
          width: "100%",
          color: "white",
          zIndex: 9
        }}
      >
        {children}
      </div>
    </div>
  );
}

TopImage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool
};
